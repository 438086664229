<template>
    <div class="product-grid d-flex bg-white">
        <div class="col-md-10 p-0">
            <div class="small-section">
                <div class="ss-header">
                    <span class="title-desc"><strong>Профиль</strong></span>
                    <router-link to="/change-password" class="edit-text float-right">сменить пароль</router-link>
                </div>
                <div class="ss-body">
                    <p v-if="profile.structural_subdivision">Структурное подразделение: <span class="field">{{ profile.structural_subdivision }}</span></p>
                    <p v-if="profile.login">Логин: <span class="field">{{ profile.login }}</span></p>
                    <p v-if="profile.name">Наименование: <span class="field">{{ profile.name }}</span></p>
                    <p v-if="profile.full_name">ФИО: <span class="field">{{ profile.full_name }}</span></p>
                    <p v-if="profile.type_user">Тип пользователя: <span class="field">{{ profile.type_user }}</span></p>
                    <p v-if="profile.contract_agency_number">Номер договора: <span class="field">{{ profile.contract_agency_number }}</span></p>
                    <p v-if="profile.contract_agency_date">Дата договора: <span class="field">{{ profile.contract_agency_date }}</span></p>
                    <p v-if="profile.delivery_address">Адреса доставки:</p>
                    <div v-if="profile.delivery_address" v-html="profile.delivery_address"></div>
                </div>
            </div>
            <div class="small-section">
                <div class="ss-header">
                    <span class="title-desc"><strong>Контактные данные</strong></span>
                </div>
                <div class="ss-body">
                    <p v-if="profile.contact_person_name">Контактное лицо: <span class="field">{{ profile.contact_person_name }}</span></p>
                    <p v-if="profile.contact_person_email">Email: <span class="field">{{ profile.contact_person_email }}</span></p>
                    <p v-if="profile.contact_person_phone">Телефон: <span class="field">{{ profile.contact_person_phone }}</span></p>
                    <p v-if="profile.contact_person_position">Должность: <span class="field">{{ profile.contact_person_position }}</span></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {get} from '@/lib/axios';

export default {
    name: 'Profile',
    data: () => ({
        profile: {
            login: '',
            name: '',
            full_name: '',
            contract_agency_number: '',
            contract_agency_date: '',
            delivery_address: null,
            contact_person_name: '',
            contact_person_email: '',
            contact_person_phone: '',
            contact_person_position: '',
            structural_subdivision: '',
            type_user: '',
        }
    }),
    methods: {
        async fetchData() {
            await get('profile').then(response => {
                this.profile = response.data.data
            })
        }
    },
    mounted() {
        this.fetchData()
    },
    metaInfo() {
        return {
            title: `Профиль - ЛУКМАРКЕТ`,
        }
    },
}
</script>

<style scoped>
.ss-header {
    padding: 20px 0;
    border-bottom: 2px solid #f5f5f5;
}

.ss-body {
    padding: 15px 0;
    font-size: 16px;
    line-height: 1.06;
    letter-spacing: normal;
    text-align: left;
}


</style>
